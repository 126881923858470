import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import uniqid from 'uniqid';
import _ from 'lodash';

import { Box, Text } from 'grommet';

import {
  Seo, AppButton, CopyButton,
} from '@Components/Control';
import { withProductAuth } from '@Components/Layout';
import { AuthNavWrapper } from '@Components/Navigation';
import { SettingsDemoContentPartial } from '@Components/Partial/Product/Cyclops';
import { paths } from '@Components/configs';
import {
  uploadCyclopsOrganizationVodRequest,
  updateCyclopsOrganizationCDNSettingsRequest,
  initiateCyclopsCDNConstantsRequest,
  clearCyclopsCDNConstantsRequest,
} from '@Actions';

import { FormInputSelector } from '../../../Partial/Product/Cyclops/CyclopsComponents';
import { FlexibleSelectDropdown } from '../../../Partial/Product/SharedComponents';
import {
  StyledCyclopsHeading,
  StyledCyclopsContainer,
  StyledHr,
} from '../../../Partial/Product/Cyclops/StyledCyclopsComponents';


const CyclopsSettingsPage = ({
  small,
  demoOnly,
  mixpanel,
  location,
  activeOrganization,
  uploadOrganizationVod,
  updateOrganizationCDNSettings,
  fetchCDNConstants,
  clearCDNConstants,
  companyOrganizations = null,
  CDNConstants = null,
  authPagesConfig = null,
  reportsConfig = null,
  cyclopsConfig = null,
  companyDemoData = null,
}) => {
  const navigate = useNavigate();
  const vodInputRef = React.useRef(null);
  const [activeCDN, setActiveCDN] = React.useState('fastly');
  const [formValues, setFormValues] = React.useState({
    inputValues: { cdn: 'fastly' },
  });
  const [vodFilename, setVodFilename] = React.useState('No vod uploaded');

  const currentOrg = _.find(companyOrganizations, { Id: activeOrganization?.uid });

  React.useEffect(() => {
    if (!demoOnly && !activeOrganization) navigate(paths.cyclopsOrganizations);
  }, [activeOrganization]);

  /* eslint-disable camelcase */
  const cloudfrontDataPath = (key) => currentOrg.cdnConfigurations?.cloudfront?.[key] ?? '';
  const fastlyDataPath = (key) => currentOrg.cdnConfigurations?.fastly?.[key] ?? '';

  React.useEffect(() => {
    if (!demoOnly && currentOrg) {
      fetchCDNConstants(currentOrg.Id);
      setVodFilename(currentOrg.vodFilename);
    }

    if (!demoOnly && currentOrg && activeCDN === 'cloudfront') {
      setFormValues({
        inputValues: {
          cdn: 'cloudfront',
          id: currentOrg.Id,
          aws_access_key_id: cloudfrontDataPath('awsAccessKeyId'),
          aws_secret_access_key: cloudfrontDataPath('awsSecretAccessKey'),
          aws_region: cloudfrontDataPath('awsRegion'),
          dynamo_db_table_name: cloudfrontDataPath('dynamoDbTableName'),
          dynamo_db_primary_key_name: cloudfrontDataPath('dynamoDbPrimaryKeyName'),
          dynamo_db_value_key_name: cloudfrontDataPath('dynamoDbValueKeyName'),
        },
      });
    }

    if (!demoOnly && currentOrg && activeCDN === 'fastly') {
      setFormValues({
        inputValues: {
          cdn: 'fastly',
          id: currentOrg.Id,
          dictionary_id: fastlyDataPath('dictionaryId'),
          service_id: fastlyDataPath('serviceId'),
          api_key: fastlyDataPath('apiKey'),
        },
      });
    }

    return () => {
      if (!demoOnly) clearCDNConstants();
    };
  }, [activeCDN, activeOrganization, currentOrg]);

  const handleFormValues = (key, value) => {
    if (key === 'cdn') setActiveCDN(value);

    setFormValues((prevState) => ({
      ...prevState,
      inputValues: {
        ...prevState.inputValues,
        [key]: value,
      },
    }));
  };

  const submitFormValues = () => {
    updateOrganizationCDNSettings(activeOrganization.uid, formValues.inputValues);
  };

  const {
    darkModeColors: {
      primaryDarkBg, containerBg, primaryText, secondaryText,
    },
    buttonHighlight,
  } = authPagesConfig;

  const renderCopyRow = (label, val, dataKey) => (
    <Box direction="row" gap="0.5rem" key={dataKey}>
      <Text size="1rem" color={secondaryText} weight={500}>
        {label}
      </Text>
      <Box direction="row" gap="0.5rem" align="center">
        <Text color={primaryText} size="1rem">
          {val ?? 'No value set'}
        </Text>
        {val && (
          <Box flex={false} pad={{ left: '0.25rem', top: '0.15rem' }}>
            <CopyButton
              renderKey={dataKey}
              value={val}
              color={primaryText}
              hoverColor={buttonHighlight}
              tooltipColor={buttonHighlight}
              tooltipPosition="right"
              data-offset="{'right': 10}"
              hideTooltip={false}
            />
          </Box>
        )}
      </Box>
    </Box>
  );

  const renderPageContent = () => {
    if (demoOnly) {
      return (
        <SettingsDemoContentPartial
          small={small}
          authPagesConfig={authPagesConfig}
          companyDemoData={companyDemoData}
        />
      );
    }

    const CDNOptions = [{ display: 'Fastly', uid: 'fastly' }, { display: 'Cloudfront', uid: 'cloudfront' }];
    const CDNInputFields = {
      fastly: [
        {
          label: 'Dictionary ID',
          placeholder: 'Fastly dictionary ID',
          key: 'dictionary_id',
        },
        {
          label: 'Service ID',
          placeholder: 'Service ID',
          key: 'service_id',
        },
        {
          label: 'API Key',
          placeholder: 'Fastly API key',
          key: 'api_key',
        },
      ],
      cloudfront: [
        {
          label: 'AWS Access Key ID',
          placeholder: 'AWS access key ID',
          key: 'aws_access_key_id',
        },
        {
          label: 'AWS Secret',
          placeholder: 'AWS secret access Key',
          key: 'aws_secret_access_key',
        },
        {
          label: 'AWS Region',
          placeholder: 'AWS Region',
          key: 'aws_region',
        },
        {
          label: 'DynamoDB Table Name',
          placeholder: 'DynamoDB Table Name',
          key: 'dynamo_db_table_name',
        },
        {
          label: 'DynamoDB Primary Key Name',
          placeholder: 'DynamoDB Primary Key Name',
          key: 'dynamo_db_primary_key_name',
        },
        {
          label: 'DynamoDB Value Key Name',
          placeholder: 'DynamoDB Value Key Name',
          key: 'dynamo_db_value_key_name',
        },
      ],
    };

    return (
      <Box flex background={primaryDarkBg} direction="column" gap="1.5rem">
        <StyledCyclopsContainer
          background={containerBg}
          direction="column"
          width={small ? '75%' : '55%'}
        >
          <Box direction="column" gap="0.5rem">
            <Text size="1.1rem" weight={600} color={primaryText}>
              CDN Configuration Constants
            </Text>
            <StyledHr color="#D0D0D0" />
          </Box>
          <Box
            direction="column"
            gap="0.5rem"
            pad={{ top: '0.5rem', bottom: '1rem' }}
          >
            <Text size="1rem" weight={500} color={primaryText}>
              Headers:
            </Text>
            {renderCopyRow('X-PID:', activeOrganization?.uid, 'pid')}
            {renderCopyRow('X-RF-Compute-Auth:', CDNConstants?.computeAuth, 'computeAuth')}
            {renderCopyRow('X-RF-Auth:', CDNConstants?.edgeAuth, 'edgeAuth')}
          </Box>
          <Box
            direction="column"
            gap="0.5rem"
            pad={{ top: '0.5rem', bottom: '1rem' }}
          >
            <Text size="1rem" weight={500} color={primaryText}>
              Cookie Decryption IV and Key:
            </Text>
            {renderCopyRow('Cookie IV:', CDNConstants?.iv, 'iv')}
            {renderCopyRow('Cookie Secret:', CDNConstants?.cookieSecret, 'cookieSecret')}
          </Box>
        </StyledCyclopsContainer>
        <StyledCyclopsContainer
          background={containerBg}
          direction="column"
          width={small ? '75%' : '55%'}
        >
          <Box direction="column" gap="0.5rem">
            <Text size="1.1rem" weight={600} color={primaryText}>
              Upload Organization Vod
            </Text>
            <StyledHr color="#D0D0D0" />
          </Box>
          <input
            hidden
            key={uniqid()}
            type="file"
            accept="video/*"
            onChange={(e) => uploadOrganizationVod(
              activeOrganization.uid, e.currentTarget.files[0],
            )}
            ref={vodInputRef}
          />
          <Box direction="column" gap="0.5rem" pad={{ top: '0.5rem', bottom: '1rem' }}>
            <Text size="1rem" weight={500} color={secondaryText}>
              Current vod filename:
            </Text>
            <Text size="1rem" color={primaryText}>
              {vodFilename}
            </Text>
          </Box>
          <AppButton
            overrideHover
            level="dynamicLarge"
            color="white"
            width="7.5rem"
            bgColor={buttonHighlight}
            onClick={() => vodInputRef.current.click()}
            fontWeight={600}
            label="Upload file"
          />
        </StyledCyclopsContainer>
        <StyledCyclopsContainer
          background={containerBg}
          direction="column"
          gap="0.5rem"
          width={small ? '90%' : '75%'}
        >
          <Text size="1.1rem" weight={600} color={primaryText}>
            Update CDN Settings
          </Text>
          <StyledHr color="#D0D0D0" />
          <Box pad={{ bottom: '1rem' }} width={small ? '55%' : '45%'}>
            <FlexibleSelectDropdown
              borderColor="white"
              customPad="0.5rem 0.6rem"
              buttonWidth="11rem"
              buttonJustify="between"
              flexibleOptions={CDNOptions}
              selectedOption={_.find(CDNOptions, { uid: formValues.inputValues.cdn }).display}
              handleItemSelect={(val) => handleFormValues('cdn', val)}
              textColor={secondaryText}
              noSelectionLabel="Select a CDN"
              displayKey="display"
              valueKey="uid"
              buttonBg={primaryDarkBg}
              selectMenuBg="#004FFE"
              selectMenuHighlight="white"
            />
          </Box>
          <Box direction="column" gap="0.5rem" pad={{ left: '0.5rem' }}>
            {CDNInputFields[activeCDN].map(({
              label, placeholder, key,
            }) => (
              <FormInputSelector
                small={small}
                key={key}
                label={label}
                renderKey="text"
                inputKey={key}
                inputType="text"
                placeholder={placeholder}
                value={formValues.inputValues[key]}
                handleFormValues={handleFormValues}
                authPagesConfig={authPagesConfig}
              />
            ))}
          </Box>
          <AppButton
            overrideHover
            level="dynamicLarge"
            color="white"
            width="7.5rem"
            bgColor={buttonHighlight}
            onClick={() => submitFormValues()}
            fontWeight={600}
            label="Save settings"
          />
        </StyledCyclopsContainer>
      </Box>
    );
  };
  /* eslint-enable camelcase */

  const pageTitle = demoOnly ? 'Cyclops Settings' : 'Organization CDN Settings';

  return (
    <AuthNavWrapper
      hideBanner
      darkMode
      small={small}
      mixpanel={mixpanel}
      location={location}
      authPagesConfig={authPagesConfig}
      reportsConfig={reportsConfig}
      cyclopsConfig={cyclopsConfig}
    >
      <Seo />
      <Box
        flex
        background={primaryDarkBg}
        pad={small ? '2rem' : '3rem'}
        direction="column"
        gap="1.5rem"
      >
        <StyledCyclopsHeading level={2} color={primaryText}>
          {pageTitle}
        </StyledCyclopsHeading>
        {renderPageContent()}
      </Box>
    </AuthNavWrapper>
  );
};

function mapStateToProps(state) {
  return {
    companyOrganizations: state.cyclops.companyOrganizations,
    activeOrganization: state.organizations.activeCompanyOrganization,
    CDNConstants: state.cyclops.cdnConstants,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      uploadOrganizationVod: uploadCyclopsOrganizationVodRequest,
      updateOrganizationCDNSettings:
        updateCyclopsOrganizationCDNSettingsRequest,
      fetchCDNConstants: initiateCyclopsCDNConstantsRequest,
      clearCDNConstants: clearCyclopsCDNConstantsRequest,
    },
    dispatch,
  );
}

CyclopsSettingsPage.propTypes = {
  small: PropTypes.bool.isRequired,
  demoOnly: PropTypes.bool.isRequired,
  mixpanel: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    constructionImage: PropTypes.string.isRequired,
    darkModeColors: PropTypes.shape({
      containerBg: PropTypes.string.isRequired,
      primaryDarkBg: PropTypes.string.isRequired,
      primaryText: PropTypes.string.isRequired,
      secondaryText: PropTypes.string.isRequired,
    }).isRequired,
  }),
  companyOrganizations: PropTypes.arrayOf(PropTypes.shape({
    Id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    companyId: PropTypes.number.isRequired,
    piracyDomains: PropTypes.arrayOf(PropTypes.string),
    encoderOutboundProtocol: PropTypes.string,
    encoderOutboundVideoCodec: PropTypes.string,
    encoderOutboundAudioCodec: PropTypes.string,
    cyclopsStartsPostAbr: PropTypes.bool,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    vodFilename: PropTypes.string,
    cdnConfigurations: PropTypes.shape({
      fastly: PropTypes.shape({
        dictionaryId: PropTypes.string,
        serviceId: PropTypes.string,
        apiKey: PropTypes.string,
      }),
      cloudfront: PropTypes.shape({
        awsAccessKeyId: PropTypes.string,
        awsSecretAccessKey: PropTypes.string,
        awsRegion: PropTypes.string,
        dynamoDbTableName: PropTypes.string,
        dynamoDbPrimaryKeyName: PropTypes.string,
        dynamoDbValueKeyName: PropTypes.string,
      }),
    }),
  })),
  activeOrganization: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  CDNConstants: PropTypes.objectOf(PropTypes.any),
  reportsConfig: PropTypes.arrayOf(PropTypes.any),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
  companyDemoData: PropTypes.objectOf(PropTypes.any),
  uploadOrganizationVod: PropTypes.func.isRequired,
  updateOrganizationCDNSettings: PropTypes.func.isRequired,
  fetchCDNConstants: PropTypes.func.isRequired,
  clearCDNConstants: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withProductAuth(CyclopsSettingsPage));
