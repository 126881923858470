/* eslint-disable global-require */
module.exports = {
  // PRODUCT
  // authActions
  authLogInRequest: require('./product/authActions').logInRequest,
  authLogOutRequest: require('./product/authActions').logOutRequest,
  authRefreshRequest: require('./product/authActions').refreshRequest,
  authUploadCompanyLogoRequest: require('./product/authActions').uploadCompanyLogoRequest,
  sendPasswordResetInstructionsRequest: require('./product/authActions').sendPasswordResetInstructionsRequest,
  updatePasswordRequest: require('./product/authActions').updatePasswordRequest,
  fetchUsersRequest: require('./product/authActions').fetchUsersRequest,
  clearUsersRequest: require('./product/authActions').clearUsersRequest,
  createNewUserRequest: require('./product/authActions').createNewUserRequest,
  deleteUserRequest: require('./product/authActions').deleteUserRequest,
  resendUserInviteRequest: require('./product/authActions').resendUserInviteRequest,
  // newActions
  initiateNewFormConfigRequest: require('./product/newActions').initiateNewFormConfigRequest,
  submitNewSearchRequest: require('./product/newActions').submitNewSearchRequest,
  initiateNewBulkFormConfigRequest: require('./product/newActions').initiateNewBulkFormConfigRequest,
  submitNewBulkSearchRequest: require('./product/newActions').submitNewBulkSearchRequest,
  submitSearchFeatureRequest: require('./product/newActions').submitSearchFeatureRequest,
  initiateProductUsageRequest: require('./product/newActions').initiateProductUsageRequest,
  clearProductUsageRequest: require('./product/newActions').clearProductUsageRequest,
  // resultsActions
  initiateResultsUploadConfigRequest: require('./product/resultsActions').initiateResultsUploadConfigRequest,
  clearResultsUploadConfigRequest: require('./product/resultsActions').clearResultsUploadConfigRequest,
  submitResultsFileUploadRequest: require('./product/resultsActions').submitResultsFileUploadRequest,
  // listActions
  initiateProductListSearchesRequest: require('./product/listActions').initiateProductListSearchesRequest,
  clearProductListSearchesRequest: require('./product/listActions').clearProductListSearchesRequest,
  initiateProductListSearchesAddedRequest: require('./product/listActions').initiateProductListSearchesAddedRequest,
  clearProductListSearchesAddedRequest: require('./product/listActions').clearProductListSearchesAddedRequest,
  initiateProductListSearchesMetaRequest: require('./product/listActions').initiateProductListSearchesMetaRequest,
  clearProductListSearchesMetaRequest: require('./product/listActions').clearProductListSearchesMetaRequest,
  initiateProductListSearchesStatsRequest: require('./product/listActions').initiateProductListSearchesStatsRequest,
  clearProductListSearchesStatsRequest: require('./product/listActions').clearProductListSearchesStatsRequest,
  initiateProductListSearchesFlexDataRequest: require('./product/listActions').initiateProductListSearchesFlexDataRequest,
  clearProductListSearchesFlexDataRequest: require('./product/listActions').clearProductListSearchesFlexDataRequest,
  // dashboardActions
  initiateProductDashboardReviewRequest: require('./product/dashboardActions').initiateProductDashboardReviewRequest,
  clearProductDashboardReviewRequest: require('./product/dashboardActions').clearProductDashboardReviewRequest,
  updateProductDashboardReviewSelectionsRequest: require('./product/dashboardActions').updateProductDashboardReviewSelectionsRequest,
  initiateProductDashboardReviewStatsRequest: require('./product/dashboardActions').initiateProductDashboardReviewStatsRequest,
  clearProductDashboardReviewStatsRequest: require('./product/dashboardActions').clearProductDashboardReviewStatsRequest,
  initiateProductDashboardReviewMetaRequest: require('./product/dashboardActions').initiateProductDashboardReviewMetaRequest,
  clearProductDashboardReviewMetaRequest: require('./product/dashboardActions').clearProductDashboardReviewMetaRequest,
  initiateProductDashboardFlexibleReviewDataRequest: require('./product/dashboardActions').initiateProductDashboardFlexibleReviewDataRequest,
  clearProductDashboardFlexibleReviewDataRequest: require('./product/dashboardActions').clearProductDashboardFlexibleReviewDataRequest,
  updateProductDashboardFlexibleReviewSelectionsRequest: require('./product/dashboardActions').updateProductDashboardFlexibleReviewSelectionsRequest,
  initiateProductDashboardSearchResultsRequest: require('./product/dashboardActions').initiateProductDashboardSearchResultsRequest,
  clearProductDashboardSearchResultsRequest: require('./product/dashboardActions').clearProductDashboardSearchResultsRequest,
  clearProductDashboardSearchResultsMetaRequest: require('./product/dashboardActions').clearProductDashboardSearchResultsMetaRequest,
  initiateProductDashboardSearchResultsMetaRequest: require('./product/dashboardActions').initiateProductDashboardSearchResultsMetaRequest,
  // settingsActions
  initiateCompanySettingsRequest: require('./product/settingsActions').initiateCompanySettingsRequest,
  clearCompanySettingsRequest: require('./product/settingsActions').clearCompanySettingsRequest,
  initiateSingleCompanySettingRequest: require('./product/settingsActions').initiateSingleCompanySettingRequest,
  clearSingleCompanySettingRequest: require('./product/settingsActions').clearSingleCompanySettingRequest,
  updateCompanyDomainRequest: require('./product/settingsActions').updateCompanyDomainRequest,

  // embedActions
  refreshEmbedAccessDataRequest: require('./product/embedActions').refreshEmbedAccessDataRequest,
  clearEmbedAccessDataRequest: require('./product/embedActions').clearEmbedAccessDataRequest,

  // CYCLOPS
  // Organizations
  initiateCyclopsCompanyOrganizationsRequest: require('./product/cyclops/organizationsActions').initiateCyclopsCompanyOrganizationsRequest,
  clearCyclopsCompanyOrganizationsRequest: require('./product/cyclops/organizationsActions').clearCyclopsCompanyOrganizationsRequest,
  submitNewCyclopsOrganizationRequest: require('./product/cyclops/organizationsActions').submitNewCyclopsOrganizationRequest,
  setCyclopsActiveOrganizationRequest: require('./product/cyclops/organizationsActions').setCyclopsActiveOrganizationRequest,
  clearCyclopsActiveOrganizationRequest: require('./product/cyclops/organizationsActions').clearCyclopsActiveOrganizationRequest,
  editCyclopsOrganizationRequest: require('./product/cyclops/organizationsActions').editCyclopsOrganizationRequest,
  deleteCyclopsOrganizationRequest: require('./product/cyclops/organizationsActions').deleteCyclopsOrganizationRequest,
  uploadCyclopsOrganizationVodRequest: require('./product/cyclops/organizationsActions').uploadCyclopsOrganizationVodRequest,
  updateCyclopsOrganizationCDNSettingsRequest: require('./product/cyclops/organizationsActions').updateCyclopsOrganizationCDNSettingsRequest,
  initiateCyclopsCDNConstantsRequest: require('./product/cyclops/organizationsActions').initiateCyclopsCDNConstantsRequest,
  clearCyclopsCDNConstantsRequest: require('./product/cyclops/organizationsActions').clearCyclopsCDNConstantsRequest,
  // Livestreams
  initiateCyclopsLivestreamsRequest: require('./product/cyclops/livestreamsActions').initiateCyclopsLivestreamsRequest,
  clearCyclopsLivestreamsRequest: require('./product/cyclops/livestreamsActions').clearCyclopsLivestreamsRequest,
  submitNewCyclopsLivestreamRequest: require('./product/cyclops/livestreamsActions').submitNewCyclopsLivestreamRequest,
  editCyclopsLivestreamRequest: require('./product/cyclops/livestreamsActions').editCyclopsLivestreamRequest,
  deleteCyclopsLivestreamRequest: require('./product/cyclops/livestreamsActions').deleteCyclopsLivestreamRequest,
  initiateCyclopsLivestreamDetailsRequest: require('./product/cyclops/livestreamsActions').initiateCyclopsLivestreamDetailsRequest,
  clearCyclopsLivestreamDetailsRequest: require('./product/cyclops/livestreamsActions').clearCyclopsLivestreamDetailsRequest,
  startOrStopCyclopsLivestreamRequest: require('./product/cyclops/livestreamsActions').startOrStopCyclopsLivestreamRequest,
  bypassPipelineCyclopsLivestreamRequest: require('./product/cyclops/livestreamsActions').bypassPipelineCyclopsLivestreamRequest,
  // Piracy Streams
  initiateCyclopsPiracyStreamsRequest: require('./product/cyclops/piracystreamsActions').initiateCyclopsPiracyStreamsRequest,
  clearCyclopsPiracyStreamsRequest: require('./product/cyclops/piracystreamsActions').clearCyclopsPiracyStreamsRequest,
  killCyclopsPiracyStreamRequest: require('./product/cyclops/piracystreamsActions').killCyclopsPiracyStreamRequest,
  // Authed Domains
  initiateCyclopsAuthedDomainsRequest: require('./product/cyclops/authedDomainsActions').initiateCyclopsAuthedDomainsRequest,
  clearCyclopsAuthedDomainsRequest: require('./product/cyclops/authedDomainsActions').clearCyclopsAuthedDomainsRequest,
  submitNewCyclopsAuthedDomainRequest: require('./product/cyclops/authedDomainsActions').submitNewCyclopsAuthedDomainRequest,
  editCyclopsAuthedDomainRequest: require('./product/cyclops/authedDomainsActions').editCyclopsAuthedDomainRequest,
  deleteCyclopsAuthedDomainRequest: require('./product/cyclops/authedDomainsActions').deleteCyclopsAuthedDomainRequest,
  // Analytics
  initiateCyclopsAnalyticsRequest: require('./product/cyclops/analyticsActions').initiateCyclopsAnalyticsRequest,
  clearCyclopsAnalyticsRequest: require('./product/cyclops/analyticsActions').clearCyclopsAnalyticsRequest,

  // OTHER
  // paymentActions
  clearPaymentTierRequest: require('./paymentActions').clearPaymentTierRequest,
  fetchPaymentTierRequest: require('./paymentActions').fetchPaymentTierRequest,
  checkoutRequest: require('./paymentActions').checkoutRequest,
  // redirectActions
  initiateRedirectRequest: require('./redirectActions').initiateRedirectRequest,
  clearRedirectRequest: require('./redirectActions').clearRedirectRequest,
  // faviconActions
  setFaviconLoading: require('./faviconActions').setFaviconLoading,
  setFaviconReady: require('./faviconActions').setFaviconReady,
  setFaviconDefault: require('./faviconActions').setFaviconDefault,
  // noticeActions
  closeNoticeRequest: require('./noticeActions').closeNoticeRequest,
  arriveNotice: require('./noticeActions').arriveNotice,
  closeCustomNoticeRequest: require('./noticeActions').closeCustomNoticeRequest,
  arriveCustomNotice: require('./noticeActions').arriveCustomNotice,
  // abortHelper
  resetAbortController: require('./aborterHelper').resetAbortController,
};
/* eslint-enable global-require */
