export const REDIRECT_REQUESTED = 'REDIRECT_REQUESTED';
export const REDIRECT_COMPLETED = 'REDIRECT_COMPLETED';

// PAYMENT SUBSCRIPTION
export const PAYMENT_TIER_LOADED = 'PAYMENT_TIER_LOADED';
export const PAYMENT_TIER_CLEAR = 'PAYMENT_TIER_CLEAR';
export const CHECKOUT_REQUESTED = 'CHECKOUT_REQUESTED';
export const CHECKOUT_CLEAR = 'CHECKOUT_CLEAR';

// PRODUCT V2
export const PRODUCT_NEW_FORM_CONFIG_LOADED = 'PRODUCT_NEW_FORM_CONFIG_LOADED';
export const PRODUCT_NEW_FORM_CONFIG_CLEAR = 'PRODUCT_NEW_FORM_CONFIG_CLEAR';
export const PRODUCT_NEW_FORM_BULK_CONFIG_LOADED = 'PRODUCT_NEW_FORM_BULK_CONFIG_LOADED';
export const PRODUCT_NEW_FORM_BULK_CONFIG_CLEAR = 'PRODUCT_NEW_FORM_BULK_CONFIG_CLEAR';
export const PRODUCT_NEW_SEARCHES_USAGE_LOADING = 'PRODUCT_NEW_SEARCHES_USAGE_LOADING';
export const PRODUCT_NEW_SEARCHES_USAGE_LOADED = 'PRODUCT_NEW_SEARCHES_USAGE_LOADED';
export const PRODUCT_NEW_SEARCHES_USAGE_CLEAR = 'PRODUCT_NEW_SEARCHES_USAGE_CLEAR';

export const PRODUCT_RESULTS_UPLOAD_CONFIG_LOADED = 'PRODUCT_RESULTS_UPLOAD_CONFIG_LOADED';
export const PRODUCT_RESULTS_UPLOAD_CONFIG_CLEAR = 'PRODUCT_RESULTS_UPLOAD_CONFIG_CLEAR';

export const PRODUCT_DASHBOARD_SEARCH_RESULTS_LOADED = 'PRODUCT_DASHBOARD_SEARCH_RESULTS_LOADED';
export const PRODUCT_DASHBOARD_SEARCH_RESULTS_CLEAR = 'PRODUCT_DASHBOARD_SEARCH_RESULTS_CLEAR';
export const PRODUCT_DASHBOARD_SEARCH_RESULTS_META_LOADED = 'PRODUCT_DASHBOARD_SEARCH_RESULTS_META_LOADED';
export const PRODUCT_DASHBOARD_SEARCH_RESULTS_META_CLEAR = 'PRODUCT_DASHBOARD_SEARCH_RESULTS_META_CLEAR';
export const PRODUCT_DASHBOARD_REVIEW_DATA_LOADED = 'PRODUCT_DASHBOARD_REVIEW_DATA_LOADED';
export const PRODUCT_DASHBOARD_REVIEW_DATA_CLEAR = 'PRODUCT_DASHBOARD_REVIEW_DATA_CLEAR';
export const PRODUCT_DASHBOARD_REVIEW_STATS_LOADED = 'PRODUCT_DASHBOARD_REVIEW_STATS_LOADED';
export const PRODUCT_DASHBOARD_REVIEW_STATS_CLEAR = 'PRODUCT_DASHBOARD_REVIEW_STATS_CLEAR';
export const PRODUCT_DASHBOARD_REVIEW_META_LOADED = 'PRODUCT_DASHBOARD_REVIEW_META_LOADED';
export const PRODUCT_DASHBOARD_REVIEW_META_CLEAR = 'PRODUCT_DASHBOARD_REVIEW_META_CLEAR';
export const PRODUCT_DASHBOARD_FLEXIBLE_DATA_LOADED = 'PRODUCT_DASHBOARD_FLEXIBLE_DATA_LOADED';
export const PRODUCT_DASHBOARD_FLEXIBLE_DATA_CLEAR = 'PRODUCT_DASHBOARD_FLEXIBLE_DATA_CLEAR';

export const PRODUCT_EMBED_ACCESS_DATA_LOADED = 'PRODUCT_EMBED_ACCESS_DATA_LOADED';
export const PRODUCT_EMBED_ACCESS_DATA_CLEAR = 'PRODUCT_EMBED_ACCESS_DATA_CLEAR';

export const PRODUCT_LIST_SEARCHES_LOADED = 'PRODUCT_LIST_SEARCHES_LOADED';
export const PRODUCT_LIST_SEARCHES_CLEAR = 'PRODUCT_LIST_SEARCHES_CLEAR';
export const PRODUCT_LIST_SEARCHES_ADDED_LOADED = 'PRODUCT_LIST_SEARCHES_ADDED_LOADED';
export const PRODUCT_LIST_SEARCHES_ADDED_CLEAR = 'PRODUCT_LIST_SEARCHES_ADDED_CLEAR';
export const PRODUCT_LIST_SEARCHES_META_LOADED = 'PRODUCT_LIST_SEARCHES_META_LOADED';
export const PRODUCT_LIST_SEARCHES_META_CLEAR = 'PRODUCT_LIST_SEARCHES_META_CLEAR';
export const PRODUCT_LIST_SEARCHES_STATS_LOADED = 'PRODUCT_LIST_SEARCHES_STATS_LOADED';
export const PRODUCT_LIST_SEARCHES_STATS_CLEAR = 'PRODUCT_LIST_SEARCHES_STATS_CLEAR';
export const PRODUCT_FLEXIBLE_DATA_LOADED = 'PRODUCT_FLEXIBLE_DATA_LOADED';
export const PRODUCT_FLEXIBLE_DATA_CLEAR = 'PRODUCT_FLEXIBLE_DATA_CLEAR';

// COMPANY SETTINGS
export const COMPANY_SETTINGS_LOADED = 'COMPANY_SETTINGS_LOADED';
export const COMPANY_SETTINGS_CLEAR = 'COMPANY_SETTINGS_CLEAR';
export const COMPANY_SINGLE_SETTING_LOADED = 'COMPANY_SINGLE_SETTING_LOADED';
export const COMPANY_SINGLE_SETTING_CLEAR = 'COMPANY_SINGLE_SETTING_CLEAR';

// PRODUCT AUTH
export const AUTH_LOG_IN = 'AUTH_LOG_IN';
export const AUTH_LOG_OUT = 'AUTH_LOG_OUT';
export const AUTH_REFRESH = 'AUTH_REFRESH';
export const AUTH_ACCOUNT_LOADED = 'AUTH_ACCOUNT_LOADED';
export const AUTH_ACCOUNT_CLEAR = 'AUTH_ACCOUNT_CLEAR';
export const AUTH_USERS_LOADED = 'AUTH_USERS_LOADED';
export const AUTH_USERS_CLEAR = 'AUTH_USERS_CLEAR';

// CYCLOPS
export const CYCLOPS_ORGANIZATIONS_DATA_LOADED = 'CYCLOPS_ORGANIZATIONS_DATA_LOADED';
export const CYCLOPS_ORGANIZATIONS_DATA_CLEAR = 'CYCLOPS_ORGANIZATIONS_DATA_CLEAR';
export const CYCLOPS_ACTIVE_ORGANIZATION_SET = 'CYCLOPS_ACTIVE_ORGANIZATION_SET';
export const CYCLOPS_ACTIVE_ORGANIZATION_CLEAR = 'CYCLOPS_ACTIVE_ORGANIZATION_CLEAR';
export const CYCLOPS_LIVESTREAMS_DATA_LOADED = 'CYCLOPS_LIVESTREAMS_DATA_LOADED';
export const CYCLOPS_LIVESTREAMS_DATA_CLEAR = 'CYCLOPS_LIVESTREAMS_DATA_CLEAR';
export const CYCLOPS_LIVESTREAM_DETAILS_LOADED = 'CYCLOPS_LIVESTREAM_DETAILS_LOADED';
export const CYCLOPS_LIVESTREAM_DETAILS_CLEAR = 'CYCLOPS_LIVESTREAM_DETAILS_CLEAR';
export const CYCLOPS_PIRACY_STREAMS_LOADED = 'CYCLOPS_PIRACY_STREAMS_LOADED';
export const CYCLOPS_PIRACY_STREAMS_CLEAR = 'CYCLOPS_PIRACY_STREAMS_CLEAR';
export const CYCLOPS_AUTHED_DOMAINS_LOADED = 'CYCLOPS_AUTHED_DOMAINS_LOADED';
export const CYCLOPS_AUTHED_DOMAINS_CLEAR = 'CYCLOPS_AUTHED_DOMAINS_CLEAR';
export const CYCLOPS_ANALYTICS_DATA_LOADED = 'CYCLOPS_ANALYTICS_DATA_LOADED';
export const CYCLOPS_ANALYTICS_DATA_CLEAR = 'CYCLOPS_ANALYTICS_DATA_CLEAR';
export const CYCLOPS_CDN_CONTSTANTS_LOADED = 'CYCLOPS_CDN_CONTSTANTS_LOADED';
export const CYCLOPS_CDN_CONTSTANTS_CLEAR = 'CYCLOPS_CDN_CONTSTANTS_CLEAR';

// OTHER
export const FETCH_LOADER_FINISHED = 'FETCH_LOADER_FINISHED';
export const FETCH_LOADER_LOADING = 'FETCH_LOADER_LOADING';
export const SECONDARY_FETCH_LOADER_FINISHED = 'SECONDARY_FETCH_LOADER_FINISHED';
export const SECONDARY_FETCH_LOADER_LOADING = 'SECONDARY_FETCH_LOADER_LOADING';
export const SUBMISSION_LOADER_FINISHED = 'SUBMISSION_LOADER_FINISHED';
export const SUBMISSION_LOADER_LOADING = 'SUBMISSION_LOADER_LOADING';

export const NOTICE_LOADED = 'NOTICE_LOADED';
export const NOTICE_CLEAR = 'NOTICE_CLEAR';
export const CUSTOM_NOTICE_LOADED = 'CUSTOM_NOTICE_LOADED';
export const CUSTOM_NOTICE_CLEAR = 'CUSTOM_NOTICE_CLEAR';

export const FAVICON_CLEAR = 'FAVICON_CLEAR';
export const FAVICON_LOADING = 'FAVICON_LOADING';
export const FAVICON_READY = 'FAVICON_READY';

export const APPLOADER_CLEAR = 'APPLOADER_CLEAR';
export const APPLOADER_READY = 'APPLOADER_READY';
